import classes from './ReviewStarsWithCounter.module.scss'
import ReviewStars from '../ReviewStars/ReviewStars'

const ReviewStarsWithCounter = ({ rating, numberOfReviews }) => {
  return (
    <span className={classes['review-stars-link']}>
      <ReviewStars rating={rating} />
      {numberOfReviews > 100 ? `${numberOfReviews} Reviews` : ``}
    </span>
  )
}

export default ReviewStarsWithCounter
