import classes from './ReviewsCarousel.module.scss'
import { useCallback } from 'react'
import Image from 'next/image'
import ReviewStars from '@/components/Review/ReviewStars/ReviewStars'
import useProductsReviews from '@/hooks/useProductsReviews'
import avatar from '@/svgs/avatar.png'
import FeaturedReview from '../FeaturedReview/FeaturedReview'

const ReviewsCarousel = ({ fields }) => {
  const reviews = useProductsReviews(
    fields?.products?.map((product) => product?.handle?.current),
    40,
  )

  const renderCard = useCallback(
    ({ id, rating, author, ugcImagePublished, body }) => {
      return (
        <div className={classes['reviews-carousel__item']} key={id}>
          <Image
            src={ugcImagePublished ? ugcImagePublished : avatar}
            width={72}
            height={72}
            alt="Review Owner Picture"
          />
          <div className={classes['reviews-carousel__item__review']}>
            <ReviewStars rating={rating} />
            <p>{`${body}`}</p>
            <p>
              {author.split(' ')[0]} {author.split(' ')[1].charAt(0)}.
            </p>
          </div>
        </div>
      )
    },
    [],
  )

  if (fields?.featuredReview && fields?.reviewType === 'single') {
    return <FeaturedReview reviewId={Number(fields?.featuredReview)} />
  }

  if (!reviews?.length) {
    return null
  }

  return (
    <section className={classes['reviews-carousel']}>
      {reviews?.map(renderCard)}
    </section>
  )
}

export default ReviewsCarousel
