import classes from "./FeaturedReview.module.scss";
import LeftQuote from "@/svgs/left-quote.svg";
import RightQuote from "@/svgs/right-quote.svg";
import avatar from "@/svgs/avatar.png";
import ReviewStars from "@/components/Review/ReviewStars/ReviewStars";
import productReviewsData from '@/utils/reviews.preval';
import Image from "next/image";
import { useMemo } from "react";
import sanitizeHtml from 'sanitize-html';

const FeaturedReview = ({ reviewId }) => {
  const review = useMemo(() => productReviewsData.find(({ id }) => id === reviewId), [reviewId])

  if (!review) {
    return null
  }

  return (
    <section className={classes["featured-review"]}>
      <div className={classes["featured-review__card"]}>
        <LeftQuote className={classes["featured-review__card__left-quote"]} />
        <div className={classes["featured-review__card__picture"]}>
          <Image
            src={review.ugcImagePublished ? review.ugcImagePublished : avatar}
            width={120}
            height={120}
            alt="Review Owner Picture"
          />
          <ReviewStars rating={5} maxWidth={100} />
        </div>
        <p>
          {sanitizeHtml(review.body)} <br /> <br /> - {review.author.split(" ")[0]} {review.author.split(" ")[1].charAt(0)}.
        </p>
        <RightQuote className={classes["featured-review__card__right-quote"]}/>
      </div>
    </section>
  );
};

export default FeaturedReview;
